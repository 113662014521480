@import "rsuite/dist/rsuite.css";

.appTitle {
  color: #fff;
  /* h5 */
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex: auto;
}

.appBarClass {
  box-shadow: none !important;
  background: transparent !important;
  border: none;
}

.toolBarClass button:hover {
  background-color: transparent !important;
}

.menuBtnClass {
  background: #3f52a3 !important;
}

.mrWrap h2 {
  margin: -10px 0 5px;
  color: var(--heading, #000);
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.reports-wrap h2 {
  color: var(--heading, #000);
  font-family: Poppins, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: -10px 0 5px;
}

.dashboardWrp h2 {
  color: var(--heading, #000);
  font-family: Poppins, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: -10px 0 5px;
}

.sideDrawerWrap {
  z-index: 1 !important;
  position: relative !important;
}

.sideDrawerWrap .appBarClass {
  z-index: 0 !important;
}

.sideDrawerWrap .drawerClass {
  z-index: -1 !important;
}

.uploadSectionWrap {
  padding-top: 10px !important;
}

.uploadAreaBtn {
  border-radius: 6px !important;
  border: 2px dashed var(--divider, #E5E5E5) !important;
  cursor: pointer !important;
  position: relative;
  background: none !important;
  box-shadow: none !important;
  text-align: center;
  justify-content: space-between !important;
  background-color: none;
  width: 100%;
  align-items: center;
  overflow: hidden;
  color: var(--heading, #000) !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.reportSectionWrap {
  padding-top: 10px !important;
}

.reportInnerWrap {
  display: flex !important;
  align-items: end;
}

.reportsWrp {
  text-align: center;
}

.ApplyGridBtn {
  padding: 12px 24px !important;
  height: 45px;
  border-radius: 8px !important;
  background: #3F52A3 !important;
  width: 92px;
  gap: 10px;
  color: white!important;
  margin-bottom: 2px;
  opacity: 1;
  cursor: pointer;
  pointer-events: auto;
}
.ApplyGridBtnDisable {
  padding: 12px 24px !important;
  height: 45px;
  opacity: 0.5;
  border-radius: 8px !important;
  background: #3F52A3 !important;
  width: 92px;
  gap: 10px;
  pointer-events: none;
  color: white!important;
  margin-bottom: 2px;
}

.reportViewSection {
  margin-top: 25px;
}


.dashboardBoxVal {
  display: flex;
}

.dashInnerBoxWrap {
  flex: 1;
  text-align: left;
  padding: 10px;
  background-color: #E5E5E5;
  margin: 10px;
  border-radius: 8px;
}

.dashBoxHeading {
  color: var(--heading, #000) !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.dashBoxVal {
  color: var(--heading, #000) !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.MuiXAxis-root,
.MuiYAxis-root {
  /* Hide border lines */
  border: none !important;
}

.MuiChartsAxis-tick,
.MuiChartsAxis-line {
  display: none;
}

.manuscriptGraph {
  color: var(--heading, #000) !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.selectFormContr {
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--divider, #E5E5E5) !important;
  border: none !important;
}

.uploadFormContr {
  width: 100%;
}

.uploadFirstGrid {
  padding-left: 0 !important;
}

.selectFormContr div:nth-child(1) {
  height: 45px !important;
  display: flex;
  align-items: center;
  border: none;
  font-style: normal !important;
  padding: 0px 5px;
}

.helperText {
  color: red !important;
  border: none !important;
}

.selectField fieldset {
  border-radius: 6px !important;
  border: 1px solid var(--divider, #E5E5E5) !important;
}

.selectField::before {
  border: none !important;
}

.itemLinkClass {
  text-decoration: none;
  width: 100%;
  color: var(--white, #FFF) !important;
}

.activeLink .singleLinkOption {
  border-radius: 8px !important;
  background: var(--white, #FFF) !important;
  color: blue !important;
  padding: 0 !important;
  margin: 10px;
  height: 45px;
}

.activeLink {
  border-radius: 8px !important;
  background: var(--white, #FFF) !important;
  display: flex;
  padding: 6px 7px;
  align-items: center;
  align-self: stretch;
  color: #3F52A3;
  /* font-14/regular */
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 400;
  line-height: normal;

}

.singleLinkOption {
  width: 100% !important;
}

.listItemName span {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.ListItemButton:focus,
.listItemButton:activeLink {
  background-color: blue;
  color: white;
}

.listItemIcon svg {
  color: #FFFFFF;
  fill: #FFFFFF;
}

.listItemIconClose svg {
  color: #FFFFFF;
  fill: #FFFFFF;
}

.activeLink .listItemIconClose svg {
  color: #3F52A3 !important;
  fill: #3F52A3 !important;
}

.activeLink .listItemIcon svg {
  color: #3F52A3 !important;
  fill: #3F52A3 !important;
}

.uploadAreaWrap {
  border-radius: 6px;
  border: 2px dashed var(--divider, #E5E5E5);
  cursor: pointer !important;
  position: relative;
  text-align: center;
  height: 45px;
  align-items: end;
  overflow: hidden;
}

.fileUploaded {
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
}

/* .uploadAreaWrap div:nth-child(1) {
  width: 100%;
  height: 100%;
} */

.uploadAreaWrap div:nth-child(2)::before {
  border-bottom: none !important;
}

.artiFilename {
  color: var(--heading, #000) !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}


.passSent {
  color: var(--success, #09AE2D);
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: #09AE2D29;
  padding: 3px 12px 3px 12px;
  border-radius: 50px;
}

.tableCellClass {
  text-align: center !important;
}

.failSent {
  color: #D23E58;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: #C6202629;
  padding: 3px 14px 3px 14px;
  border-radius: 50px;
}

.passReas {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.failReas {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: 7px;
}

.artiProcessID {
  color: var(--gray-dark, #5D6166) !important;
  display: block;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.tableHeadName {
  color: var(--gray-dark, #5D6166) !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: bolder !important;
  line-height: normal !important;
  text-align: center !important;
}

.fileNameHeader {
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.uploadAreaWrap div:nth-child(2)::after {
  border-bottom: none !important;
}

.uploadAreaWrap div:nth-child(1):hover::before {
  border-bottom: none !important;
}

.uploadAreaWrap div:nth-child(1):hover::after {
  border-bottom: none !important;
}

.uploadIconTxt {
  cursor: pointer !important;
}

/* .spiralIcon{
  display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 5px;
    vertical-align: middle;
    border: 3px solid rgb(36 96 167);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
} */
.spiralIcon {
  width: 20px;
  height: 20px;
  animation: rotation 2s infinite linear;
}

.spiralIcon img {
  width: 100%;
  height: 100%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.uploadIconWrap {
  margin-left: 15px;
}

.uploadIconWrap img {
  vertical-align: bottom;
}

#uploadFile {
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}


.snackContent {
  display: flex !important;
  width: 360px;
  padding: 6px 16px !important;
  justify-content: center !important;
  border-radius: 5px !important;
  border: 1px solid var(--divider, #E5E5E5) !important;
  border-left: 3px solid #09AE2D !important;
  background: var(--white, #FFF) !important;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08) !important;
  align-items: center !important;
  gap: 16px;
}

.snacktextWrap {
  display: flex;
  align-items: center;
}

.hdSnackTxt {
  color: var(--success, #09AE2D);
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.subSnackTxt {
  color: var(--heading, #000);
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.checkIcon {
  color: #09AE2D;
  margin-right: 10px;
}

.sideNavWrap {
  background-color: #3f52a3;
  color: #ffffff;
  height: 100%;
}

.snackIconClose {
  color: #5D6166 !important;
  display: flex !important;
  width: 24px;
  height: 24px;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px;
  flex-shrink: 0 !important;
}

.listItemIcon {
  width: 20px;
  height: 20px;
  min-width: 0 !important;
  margin-right: 10px;
}

.listItemIconClose {
  width: 20px;
  height: 20px;
  min-width: 0 !important;
}

.sideNavHeader {
  background-color: #3f52a3;
  color: #ffffff;
  width: 100%;
}

.logoClass {
  width: 100%;
  justify-content: center;
  display: flex;
}

.mainLogo{
  align-items: center;
  background: #fff;
  background: var(--white, #fff);
  border-radius: 8px;
  display: flex;
  gap: 8px;
  height: 100%;
  padding: 9px 5px;
  width: 200px;
}
.mainLogoClass{
  justify-content: center;
    display: flex;
}
.uploadGrid{
  align-self: center;
  margin-left: 30px;
}
.LogoShort {
  border-radius: 8px;
  background: var(--white, #FFF);
  display: flex;
  width: 35px;
  height: 35px;
  padding: 9px 5px;
  align-items: center;
  gap: 8px;
}

.fullLogo {
  border-radius: 8px;
  background: var(--white, #FFF);
  display: flex;
  width: 100px;
  height: 35px;
  padding: 9px 5px;
  align-items: center;
  gap: 8px;
}

.menuOpenClass {
  display: flex !important;
  width: 27px;
  height: 25px;
  justify-content: center !important;
  align-items: center !important;
  gap: 20px;
  position: absolute !important;
  left: -25px !important;
  top: -9px !important;
  border-radius: 50px;
  border: 1px solid var(--divider, #E5E5E5) 1im;
  background-color: var(--white, #FFF) !important;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.02);
}

.menuCloseClass {
  display: flex;
  width: 27px;
  height: 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  left: 38px;
  top: -5px;
  border-radius: 50px;
  border: 1px solid var(--divider, #E5E5E5);
  background: var(--white, #FFF);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.02);
}

.leftPanelTitles {
  color: #fff !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin-left: 15px !important;
  opacity: 0.7 !important;
  margin-top: 5px !important;
}

.fieldLabel {
  color: var(--heading, #000);
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  margin-bottom: 5px;
}

.searchIconClass {
  width: 300px;
}

.searchtextFieldClass {
  width: 300px;
  text-align: center;
}

.searchtextFieldClass label {
  width: 165px;
  text-align: center;
}

.innerListBtnWrap {
  display: flex;
  align-items: center;
  width: 100%;
}

.searchtextFieldClass div:nth-child(2) {
  padding-left: 0 !important;
  height: 50px;
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
}

.tableSectionWrap {
  box-shadow: none !important;
}

.dispTable {
  border: 1px solid #e5e5e5;
}

.dispTableRow {
  background-color: #f5f5f5;
}

.searchtextFieldClass div:nth-child(2) fieldset {
  border-radius: 6px;
  border: 1px solid var(--divider, #E5E5E5);
}

.infoIcon {
  margin-left: 5px;
  vertical-align: middle;
  cursor: pointer;
}

.artiTableClass {
  text-align: left !important;
}

.inprogressText {
  border-radius: 50px;
  background: rgba(247, 143, 30, 0.16);
  color: var(--secondary, #F78F1E);
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 85px;
}

.mlaRepWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
  margin: 0 auto;
  text-align: center;
}

.mlaRepWrapTech {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 200px;
  margin: 0 auto;
  text-align: center;
}

.mlaRepProgressWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  margin: 0 auto;
  text-align: center;
}

.mlaPercPass {
  color: var(--success, #09AE2D);
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 3px 12px;
  border-radius: 50px;
  width: 75px;
  text-align: center;
  background: rgba(9, 174, 45, 0.16);
}

.mlaPercFail {
  color: var(--warning, #C62026);
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 3px 12px;
  border-radius: 50px;
  width: 75px;
  text-align: center;
  background: rgba(198, 32, 38, 0.16);
}

.failedText {
  color: var(--warning, #C62026);
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 3px 12px;
  border-radius: 50px;
  width: 75px;
  text-align: center;
  background: rgba(198, 32, 38, 0.16);
  margin: auto;
}
.langProSnackBar{
  background-color: #3f52a3 !important;
  border-radius: 5%;
}
.langProcessSnack{
  background-color: #3f52a3 !important;
  border-radius: 5%;
}
.intensiveEditIcon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconIntensiveDisabled {
  pointer-events: none;
  opacity: 0.2;
}

.reportView {
  cursor: pointer;
}

.pdfDownIcon {

  cursor: pointer;
}

.processBtn {
  color: #FFFFFF;
  background-color: #3F52A3 !important;
  width: 113px;
  margin-bottom: 5px !important;
  position: relative !important;
  top: 29px;
}

.processBtn:disabled {
  /* Disabled button style */
  opacity: 0.7;
  color: #FFFFFF !important;
  background-color: #3F52A3 !important;
}

.barchartInfo {
  display: flex;
  justify-content: center;
}

.seperateInfo {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoDot1 {
  background-color: #3F52A3;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}

.infoDot2 {
  background-color: #F78F1E;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}

.infoDot3 {
  background-color: #09ae2d;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}

.infoDot4 {
  background-color: #c62026;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}

.fileProcessedGraph {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mrChartWrap {
  padding: 10px;
  margin-bottom: 25px;
  border: 1px solid #ececec;
  border-radius: 8px;
  margin: 10px;
}

.laqChartWrap {
  padding: 10px;
  margin-bottom: 25px;
  border: 1px solid #ececec;
  border-radius: 8px;
  margin: 10px;
}

.footerWrap {
  display: flex;
  position: fixed;
  bottom: 0;
  margin: 0;
  left: 0px;
  text-align: center;
  background: #e9e9e9;
  width: 100%;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  color: #b3afaf;
}

.footerTextWrap {
  display: flex;
  align-items: center;
}

.poweredImgWrap {
  display: flex;
}

.poweredImgWrap img {
  width: 60px;
  margin-left: 5px;
  margin-right: 5px;
}

.processBtn:hover:disabled {
  /* Hover effect for disabled button */
  opacity: 0.7;
  color: #FFFFFF !important;
  background-color: #3F52A3 !important;
}

.processBtn:active:disabled {
  /* Active effect for disabled button */
  opacity: 0.7;
  color: #FFFFFF !important;
  background-color: #3F52A3 !important;
}

#responsive-dialog-title {
  color: #252525;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.infoList li::marker {
  color: #3F52A3;
}

.infoList li {
  color: #252525;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  list-style-type: disc;
}

.closeIcon {
  position: absolute;
  right: 15px;
  top: 40px;
  cursor: pointer;
}

.closeIcon span img {
  position: absolute;
  bottom: 0;
  right: 0;
}


/* DatePicker css */
.datePickerRangeWrap {
  display: flex;
  justify-content: end;
}

.rs-calendar-header-title {
  font-size: 16px !important;
  font-weight: 700 !important;
  font-family: 'Poppins', sans-serif !important;
  color: #000 !important;
}

.rs-picker-daterange-predefined .rs-btn {
  color: #000 !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  width: 100px !important;
  text-align: left !important;
}

.rs-calendar-table-cell,
.rs-calendar-table-header-cell {
  color: #000 !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400;

}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  border-radius: 50px !important;
  color: #fff !important;
  background-color: #3F52A3 !important;

}

.rs-picker-daterange-header {
  display: none;
}

.rs-btn-sm {
  font-family: 'Poppins', sans-serif !important;
  color: #000 !important;
}

.rs-btn-primary {
  background: var(--primary, #3F52A3) !important;
  color: #fff !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400;
}

.rs-picker-daterange-predefined {
  height: 275px !important;
  border-bottom: 1px solid #e5e5ea;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 11px !important;

}


.rs-btn-link:hover {
  text-decoration: none !important;
  background-color: #cadcf1 !important;
  width: 100px !important;
  color: #3F52A3 !important;
  border-radius: none !important;
  text-align: left !important;
}

.rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar {
  max-width: 355px !important;
}

.rs-picker-toolbar {
  justify-content: left !important;
  margin-left: -90px !important;
}

.rs-input-group.rs-input-group-inside {
  height: 98%;
}
.MuiMenu-paper{
  top: 38px;
    left: 1170px;
    transform-origin: 136px 0px;
    margin-top: 0px !important;
}
.userProfileDropWrap{
  position: absolute;
  right: 15px;
}
/* userDropDown CSS */
.userProfileDropWrap{
  position: absolute !important;
  right: 15px;
}
.avatar-container {
  width: 40px;
  height: 40px;
}
 
.avatar-svg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #3f52a3;
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.avatar-text {
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 21px !important;
  color: #ffffff;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif !important;
  fill: #ffffff;
}
.Logout_menuItem,
.Profile_menuItem {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  color: #000000 !important;
  background-color: white;
  font-family: 'Poppins', sans-serif !important;
}
 
.Logout_menuItem {
  /* background-color: #f5f5f5 !important; */
  border-bottom: 0px !important;
}

.modelPopup .MuiPaper-root,
.modelPopupLaunch .MuiPaper-root {
  width: 540px !important;
  overflow: hidden;
  border-radius: 8px;
  height: 217px !important;
}
.content_popup_delete {
  text-align: center;
  overflow: hidden !important;
}

.content_popup_delete .heading_popup {
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  text-align: center;
  font-family: 'Poppins', sans-serif !important;
  color: black !important;
}

.content_popup_delete .para_popup {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: center !important;
  font-family: 'Poppins', sans-serif !important;
  color: rgba(93, 97, 102, 1) !important;
}
.heading_popup{
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #000000 !important;
}
.content_popup_delete .heading_popup {
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  text-align: center;
  font-family: 'Poppins', sans-serif !important;
  color: black !important;
}
.button_popup {
  justify-content: center !important;
  gap: 24px !important;
}
.button_popup_Save_prompt{
  height: 77px !important;
  gap: 16px !important;
  border-top: 1px solid #E5E5E5 !important;
  justify-content: left !important;
  padding: 16px !important;
}
.button_popup_image_library{
  height: 77px !important;
  gap: 16px !important;
  justify-content: left !important;
  padding: 0px 16px !important;
}
.button_popup_Attachment{
  height: 77px !important;
  gap: 16px !important;
  border-top: 1px solid #E5E5E5 !important;
  justify-content: left !important;
  padding: 16px !important;
}
.button_popup_cancel_subs{
  height: 89px !important;
  padding: 22px 16px !important;
  gap: 16px !important;
  border-top: 1px solid #E5E5E5 !important;
  justify-content: left !important;
}
.closeConfirmButton_cancel_subs {
  text-transform: unset !important;
  color: #ffffff !important;
  font-weight: 700 !important;
  height: 45px !important;
  padding: 12px 24px 12px 24px !important;
  width: 148px !important;
  gap: 8px;
  border-radius: 8px;
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  line-height: 21px !important;
}
.closeConfirmButtonLaunch {
  text-transform: unset !important;
  /* background-color: #3f52a3; */
  /* color: #ffffff !important; */
  font-weight: 700 !important;
  font-family: 'Poppins', sans-serif !important;
  padding: 10px 20px !important;
}

.closeConfirmButton {
  text-transform: unset !important;
  /* background-color: rgba(198, 32, 38, 1) !important; */
  color: #ffffff !important;
  font-weight: 700 !important;
  /* width: 96px !important; */
  height: 45px !important;
  padding: 12px 24px 12px 24px !important;
  gap: 8px;
  border-radius: 8px;
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  line-height: 21px !important;
}
.closeConfirmButton_in_Pricing {
  text-transform: unset !important;
  background-color: rgba(63, 82, 163, 1) !important;
  color: #ffffff !important;
  font-weight: 700 !important;
  width: 147px !important;
  height: 45px !important;
  padding: 12px 24px 12px 24px;
  gap: 8px;
  border-radius: 8px;
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  line-height: 21px !important;
}

.newcloseConfirmButton_in_Pricing {
  text-transform: unset !important;
  background-color: rgba(63, 82, 163, 1) !important;
  color: #ffffff !important;
  font-weight: 700 !important;
  height: 45px !important;
  padding: 12px 24px 12px 24px !important;
  gap: 8px;
  border-radius: 8px;
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  line-height: 21px !important;
}
.closeConfirmButton_Status {
  text-transform: unset !important;
  /* background-color: rgba(198, 32, 38, 1) !important; */
  color: #ffffff !important;
  font-weight: 700 !important;
  width: 147px !important;
  height: 45px !important;
  padding: 12px 24px 12px 24px;
  gap: 8px;
  border-radius: 8px;
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  line-height: 21px !important;
}
.closeButtonPopup_cancel_subs {
  text-transform: unset !important;
  border: 1px solid #C6C6C6 !important;
  background-color: white !important;
  color: black !important;
  font-weight: 700 !important;
  width: 105px !important;
  height: 45px !important;
  border-radius: 8px !important;
  padding: 12px 24px 12px 24px !important;
  font-family: 'Poppins', sans-serif !important;
  gap: 8px !important;
  font-size: 14px !important;
  line-height: 21px !important;
  margin-left: 8px !important;
}
.closeButtonPopupLaunch {
  text-transform: unset !important;
  border: 1px solid rgba(198, 198, 198, 1) !important;
  background-color: white !important;
  color: black !important;
  font-weight: 700 !important;
  font-family: 'Poppins', sans-serif !important;
  padding: 10px 20px !important;
}

.closeButtonPopup {
  text-transform: unset !important;
  border: 1px solid rgba(198, 198, 198, 1) !important;
  background-color: white !important;
  color: black !important;
  font-weight: 700 !important;
  /* width: 105px !important; */
  height: 45px !important;
  border-radius: 8px !important;
  padding: 12px 24px 12px 24px !important;
  font-family: 'Poppins', sans-serif !important;
  gap: 8px !important;
  font-size: 14px !important;
  line-height: 21px !important;
}